import React, {useEffect, useState} from 'react';
import Page from "../../Components/Page";
import {LoadingWrapper} from "../../Components/LoadingWrapper";
import {makeApolloClientCall} from "../../../Api/makeApolloApiCall";
import SelectDropdown from "../../Components/Dropdowns/SelectDropdown";
import moment from "moment/moment";
import PeriodReleaseAuditChangesTable from "./PeriodReleaseAuditChangesTable";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import PeriodReleaseModal from "./PeriodReleaseModal";
import {makeApiCall} from "../../../Api/makeApiCall";

const PeriodReleaseAuditChanges = () => {

    const [periodOptions, setPeriodOptions] = useState(undefined);
    const [selectedPeriod, setSelectedPeriod] = useState(undefined);
    const [measuresStart, setMeasuresStart] = useState([]);
    const [measuresStop, setMeasuresStop] = useState([]);
    const [blockedItemCodes, setBlockedItemCodes] = useState([]);
    const [storeClosures, setStoreClosures] = useState([]);
    const [deletedData, setDeletedData] = useState([]);
    const [marketService, setMarketService] = useState([]);
    const [loading, setLoading] = useState(true);

    const currentDate = moment(Date.now()).format('yyyy-MM-DD');

    const getByKey = (data, key) => (data.find(config => Object.keys(config)[0] === key) || {})[key]

    function formatAuditLogs(data, configType) {
        let formatedLogs = []
        const configData = getByKey(data, configType);
        configData?.map(record => {
            const configName = Object.keys(record)[0];
            record[configName].forEach(auditEvent => {
                formatedLogs.push({...auditEvent, configName: configName});
            });
        })
        return formatedLogs
    }

    useEffect(() => {
        if (selectedPeriod) {
            setLoading(true)
            const configAuditUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/periodReleaseAuditChanges/${selectedPeriod.value}`;
            makeApiCall(configAuditUrl)
                .then(data => {
                        const measuresStartUpdated = formatAuditLogs(data, 'start_measures');
                        setMeasuresStart(measuresStartUpdated);

                        const measuresStopUpdated = formatAuditLogs(data, 'stop_measures');
                        setMeasuresStop(measuresStopUpdated);

                        const blockedItemCodesUpdated = formatAuditLogs(data, 'blocked_item_codes');
                        setBlockedItemCodes(blockedItemCodesUpdated);

                        const storeClosuresUpdated = formatAuditLogs(data, 'store_closures');
                        setStoreClosures(storeClosuresUpdated);

                        const deletedDataUpdated = formatAuditLogs(data, 'data_deleted');
                        setDeletedData(deletedDataUpdated);

                        const marketServiceUpdated = formatAuditLogs(data, 'market_service');
                        setMarketService(marketServiceUpdated);
                    }
                ).finally(() => setLoading(false))

        }
    }, [selectedPeriod, periodOptions])

    const periodDetailsQuery = "{ periods( periodEndWeeksOnly: true  ) { endDate periodAbbreviation getPriorPeriod(periodsPrior: 1, periodType: QUAD) { endDate } } }"

    useEffect(() => {
        makeApolloClientCall(periodDetailsQuery)
            .then(data => {
                    return data.periods
                        .filter((period) => period.endDate <= currentDate)
                        .map((period) => {
                            const startDate = period?.getPriorPeriod?.endDate
                            return {label: period.periodAbbreviation, value: startDate}
                        })
                }
            ).then(periodWithStartDateOfLastPeriod => setPeriodOptions(periodWithStartDateOfLastPeriod));
    }, [])


    return (<Page name={"Period Release Audit Changes"}>
        <SelectDropdown
            options={periodOptions}
            id={'period_options_dropdown'}
            inputLabel={"Period"}
            selectValue={selectedPeriod?.value}
            selectLabel={selectedPeriod?.label}
            onChange={(option) => setSelectedPeriod(option)}
        />
        <br/>
        <br/>
        {selectedPeriod && <LoadingWrapper loading={loading}>
            <div>
                <h4>{`Changes between ${selectedPeriod.value} - ${currentDate}`}</h4>
                <hr className={"draw-a-line"}/>

                <Tabs>
                    <TabList>
                        <Tab data-testid={'measures-start-tab-name'}>Measures Start</Tab>
                        <Tab data-testid={'measures-stop-tab-name'}>Measures Stop</Tab>
                        <Tab data-testid={'blocked-item-codes-tab-name'}>Blocked Item Codes</Tab>
                        <Tab data-testid={'store-closures-tab-name'}>Store Closures</Tab>
                        <Tab data-testid={'data-deleted-tab-name'}>Data Deleted</Tab>
                        <Tab data-testid={'market-service-tab-name'}>Market Service</Tab>
                    </TabList>
                    <TabPanel data-testid={'measures-start-tab-panel'}>
                        <PeriodReleaseAuditChangesTable
                            data={measuresStart}
                            customColumns={[
                                {header: 'Spins Store Id', accessorKey: 'storeId'},
                                {header: 'Sales End Date', accessorKey: 'salesEndDate'}
                            ]}
                            gcsFolderPath={"measures/storeStart"}
                            headerValue={"Measures Start"}
                        />
                    </TabPanel>
                    <TabPanel data-testid={'measures-stop-tab-panel'}>
                        <PeriodReleaseAuditChangesTable
                            data={measuresStop}
                            customColumns={[
                                {header: 'Spins Store Id', accessorKey: 'storeId'},
                                {header: 'Sales End Date', accessorKey: 'salesEndDate'}
                            ]}
                            gcsFolderPath={"measures/storeStop"}
                            headerValue={"Measures Stop"}
                        />
                    </TabPanel>
                    <TabPanel data-testid={'blocked-item-codes-tab-panel'}>
                        <PeriodReleaseAuditChangesTable
                            data={blockedItemCodes}
                            customColumns={[
                                {header: 'Spins Store Id', accessorKey: 'spinsStoreId'},
                                {header: 'Spins Item Code', accessorKey: 'spinsItemCode'},
                                {header: 'Sales End Date', accessorKey: 'salesEndDate'}
                            ]}
                            gcsFolderPath={"blockedItemCodes"}
                            headerValue={"Blocked Item Codes"}
                        />
                    </TabPanel>
                    <TabPanel data-testid={'store-closures-tab-panel'}>
                        <PeriodReleaseAuditChangesTable
                            data={storeClosures}
                            customColumns={[
                                {header: 'Store Id', accessorKey: 'storeId'},
                                {header: 'Start Date', accessorKey: 'startDate'},
                                {header: 'End Date', accessorKey: 'endDate'}
                            ]}
                            gcsFolderPath={"storeClosures"}
                            headerValue={"Store Closures"}
                        />
                    </TabPanel>
                    <TabPanel data-testid={'data-deleted-tab-panel'}>
                        <PeriodReleaseAuditChangesTable
                            data={deletedData}
                            customColumns={[
                                {header: 'Date', accessorKey: 'date_string'}
                            ]}
                            gcsFolderPath={"deleteRetailerJsonByDate"}
                            headerValue={"Data Deleted"}
                        />
                    </TabPanel>
                    <TabPanel data-testid={'market-service-tab-panel'}>
                        <PeriodReleaseAuditChangesTable
                            data={marketService}
                            customColumns={[
                                {
                                    header: 'Record',
                                    accessorKey: 'record',
                                    cell: ({row}) => <PeriodReleaseModal row={row.original}/>
                                }
                            ]}
                            gcsFolderPath={"market/periods"}
                            headerValue={"Market Service"}
                        />
                    </TabPanel>
                </Tabs>
            </div>
        </LoadingWrapper>}

    </Page>);
};


export default PeriodReleaseAuditChanges;